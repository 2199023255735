import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AppSandboxService } from 'src/modules/app/app-sandbox.service';

@Component({
  selector: 'kyt-alert-insight',
  template: `
    <div class="logo">
        <img
             src="../../../../assets/images/logo-kyt.svg"
             alt="total-energies-logo"/>
    </div>
    <div *ngIf="view.admin.includes(view.role)" class="information-container">

      <span>
        <span>There are {{alertInsight?.alertsProcessed.analystProfile}} alerts to process for analyst</span>
        <span>There are {{alertInsight?.alertsProcessed.affiliateProfile}} alerts to process for affiliate</span>
        <span *ngIf="alertInsight?.newUserAdded && alertInsight?.lastUserCreatedOn">Last user creation date {{ alertInsight?.lastUserCreatedOn | date:'dd/MM/yyyy'}}</span>
        <span>New user: {{alertInsight?.newUserAdded}}</span>
      </span>
      <span *ngIf="alertInsight?.currentDate">Current date: {{ alertInsight?.currentDate | date:'dd/MM/yyyy'}}</span>
    </div>
    <kyt-progress-bar [view]= "view" [date]="getDate(alertInsight?.week?.date)" [title]="'Week'" [status]="alertInsight?.week"></kyt-progress-bar>
    <kyt-progress-bar [view]= "view" [date]="getDate(alertInsight?.monday?.date)" [title]="'Monday'" [status]="alertInsight?.monday"></kyt-progress-bar>
    <kyt-progress-bar [view]= "view" [date]="getDate(alertInsight?.tuesday?.date)" [title]="'Tuesday'" [status]="alertInsight?.tuesday"></kyt-progress-bar>
    <kyt-progress-bar [view]= "view" [date]="getDate(alertInsight?.wednesday?.date)" [title]="'Wednesday'" [status]="alertInsight?.wednesday"></kyt-progress-bar>
    <kyt-progress-bar [view]= "view" [date]="getDate(alertInsight?.thursday?.date)" [title]="'Thursday'" [status]="alertInsight?.thursday"></kyt-progress-bar>
    <kyt-progress-bar [view]= "view" [date]="getDate(alertInsight?.friday?.date)" [title]="'Friday'" [status]="alertInsight?.friday"></kyt-progress-bar>
  `,
  styleUrls: ['./alert-insight.component.scss'],
})
export class AlertInsightComponent {
@Input() alertInsight;
 panelOpenState = true;
 view = {
  role  : this.sb?.currentUser?.getValue()?.role,
  admin : ['Administrator', 'Reader', 'Fraud Analyst', 'Analyst ML'],
  affiliate : ['Administrator', 'Reader', 'Fraud Analyst', 'Affiliate_TF', 'Affiliate_TK',
    'Affiliate_TN', 'Affiliate_TB', 'Affiliate_TL', 'Affiliate_TD', 'Armatis', 'Analyst ML'] };

  methods = {
    date: (timeStamp) => {
      return this.datePipe.transform(`${timeStamp}`, 'dd/MM/yyyy');
    }
  };

  constructor(private sb: AppSandboxService, private datePipe: DatePipe) { }

  getDate(date: string[]): any{
    if (date){
      return  {
        startDate: date[date.length - 1] ? date[date.length  - 1] : null,
        endDate: date[0] === date[date.length - 1] ? null : date[0]
      };
    }else{
      return {
        startDate: null,
        endDate: null,
      };
    }
  }

}
