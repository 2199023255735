import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import ExcelExport from 'export-xlsx';
import { TransactionList } from 'src/modules/types/interfaces';
import { alignment, defaultDataType } from 'export-xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportTransactionsService {

  apiUrl = environment.apiUrl;
  data = [];
  constructor(private http: HttpClient, private spinnerService: NgxSpinnerService) {
  }

  
  settingsForExportAffiliates = {
    // Table settings
    fileName: '',
    workSheets: [
      {
        sheetName: '',
        startingRowNumber: 1,
        gapBetweenTwoTables: 1,
        tableSettings: {
          data: {
            headerDefinition: [
              {
                name: 'Date',
                key: 'date',
                width: 20
              },
              {
                name: 'Time',
                key: 'time',
                width: 20
              },
              {
                name: 'Customer Name',
                key: 'customer_name',
                width: 20
              },
              {
                name: 'Card',
                key: 'pcom_number',
                width: 20
              },
              {
                name: 'Amount',
                key: 'amount',
                width: 20,
                dataType: defaultDataType.number,
                style: { alignment: alignment.middleCenter },
              },
              {
                name: 'Quantity',
                key: 'quantity',
                width: 20
              }, 
              {
                name: 'Site Name',
                key: 'site_name',
                width: 20
              }, 
              {
                name: 'Zip Code',
                key: 'zip_code',
                width: 20
              }, 
              {
                name: 'Town',
                key: 'town',
                width: 20
              }
            ],
          }
        }
      },
    ],
  };

  settingsForExport = {
    // Table settings
    fileName: '',
    workSheets: [
      {
        sheetName: '',
        startingRowNumber: 1,
        gapBetweenTwoTables: 1,
        tableSettings: {
          data: {
            headerDefinition: [
              {
                name: 'Issuer',
                key: 'issuer',
                width: 20,
              }, {
                name: 'Alert Type',
                key: 'alert_type',
                width: 20
              }, {
                name: 'Alert Score',
                key: 'alert_score',
                width: 20
              }, {
                name: 'Amount',
                key: 'amount',
                width: 20,
                dataType: defaultDataType.number,
                style: { alignment: alignment.middleCenter },
              }, {
                name: 'Customer Code',
                key: 'customer_code',
                width: 20
              }, {
                name: 'Customer Name',
                key: 'customer_name',
                width: 20
              }, {
                name: 'RC',
                key: 'RC',
                width: 20
              }, {
                name: 'Card',
                key: 'pcom_number',
                width: 20
              }, {
                name: 'Expired Card',
                key: 'expired_card',
                width: 20
              }, {
                name: 'Expired Card Flag',
                key: 'expired_card_flag',
                width: 20
              }, {
                name: 'Card Creation Date',
                key: 'card_creation_date',
                width: 20
              }, {
                name: 'Date',
                key: 'date',
                width: 20
              }, {
                name: 'Time',
                key: 'time',
                width: 20
              }, {
                name: 'Product Family Label',
                key: 'product_family_label',
                width: 20
              }, {
                name: 'Quantity',
                key: 'quantity',
                width: 20
              }, {
                name: 'Site Code',
                key: 'site_code',
                width: 20
              }, {
                name: 'Site Name',
                key: 'site_name',
                width: 20
              }, {
                name: 'Zip Code',
                key: 'zip_code',
                width: 20
              }, {
                name: 'Town',
                key: 'town',
                width: 20
              }, {
                name: 'Supplier Code',
                key: 'supplier_code',
                width: 20
              }, {
                name: 'Supplier Name',
                key: 'supplier_name',
                width: 20
              }, {
                name: 'Product Type',
                key: 'product_type',
                width: 20
              }, {
                name: 'Fleet Manager',
                key: 'fleet_manager',
                width: 20
              }, {
                name: 'Fleet Manager Phone Number',
                key: 'fleet_manager_phone_number',
                width: 20
              }, {
                name: 'Fleet Manager Email',
                key: 'fleet_manager_mail',
                width: 20
              }, {
                name: 'Internal Reference',
                key: 'internal_reference',
                width: 20
              }, {
                name: 'Comments',
                key: 'comments',
                width: 20
              }, {
                name: 'Transaction type',
                key: 'transaction_type',
                width: 20
              }, {
                name: 'Internal acceptance Code',
                key: 'code_acceptance_internal',
                width: 20
              }, {
                name: 'External acceptance Code',
                key: 'code_acceptance_external',
                width: 20
              }, {
                name: 'Status Code',
                key: 'status_code',
                width: 20
              }, {
                name: 'Opposition Date',
                key: 'pcom_opposition_date',
                width: 20
              }, {
                name: 'Skimming Flag',
                key: 'skimming_flag',
                width: 20,

              }
            ]
          }
        }
      },
    ],
  };

  downloadTransactions(alertUid: string, date: string, container: string, role: string): void {
    this.data = [];
    this.spinnerService.show().then();
    this.http.get<TransactionList[]>(`${this.apiUrl}/export-transactions?date=${date}&alert_uuid=${alertUid}&container=${container}`, {
      responseType: 'json'
    }
    ).subscribe((response) => {
      this.generateExcel(response, date, role);
      this.spinnerService.hide().then();
    }, error => { });
  }

  generateExcel(response: TransactionList[], date: string, userRole: string): void {
    this.data = [];
    const excelExport = new ExcelExport();
    if (['Affiliate_TF', 'Affiliate_TB', 'Affiliate_TD', 'Affiliate_TK', 'Affiliate_TN', 'Affiliate_TL', 'Armatis'].includes(userRole)) {
          const newData = response.map(item => {
            Object.keys(item).forEach((key) => {
              if (key === 'product_family' && item[key] === 'FA01') {
                item.amount = '0';
              }
            })
            return item
          })
        this.data.push({ data: newData });
        this.settingsForExportAffiliates.fileName = `${response[0].issuer}_DNA_${response[0].customer_code}_${response[0].pcom_number}_${date.replace(/-/g, '')}`;
        this.settingsForExportAffiliates.workSheets[0].sheetName = 'Transactions';      
        excelExport.downloadExcel(this.settingsForExportAffiliates, this.data);
    }
    else{
      this.data.push({ data: response });
      this.settingsForExport.fileName = `${response[0].issuer}_DNA_${response[0].customer_code}_${response[0].pcom_number}_${date.replace(/-/g, '')}`;
      this.settingsForExport.workSheets[0].sheetName = 'Transactions';
      excelExport.downloadExcel(this.settingsForExport, this.data);
    }
  }
}
