import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ACCESS } from 'src/modules/app/tools/access';
import {environment} from '../../../../environments/environment';
import { CognitoService } from '../../services/cognito/cognito.service';
import { ConfigService } from '../../services/config/config.service';

@Component({
  selector: 'kyt-header',
  styleUrls: ['./header.component.scss'],
  template: `
    <mat-toolbar>
      <a>
        <img class="logo"
             src="../../../../assets/images/logo-kyt.svg"
             alt="total-energies-logo"/>
      </a>
      <span class="kyt-name">
        <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex" (selectedTabChange)="tabClick($event)">
                  <mat-tab  *ngIf="access['home'].main_route_access.includes(user?.role)" label="HOMEPAGE"></mat-tab>
                  <mat-tab  *ngIf="access['kyt'].main_route_access.includes(user?.role)" label="ALERTES LISTES"></mat-tab>
                  <mat-tab  *ngIf="access['alerts-follow-up'].main_route_access.includes(user?.role)" label="ALERTS FOLLOW-UP"></mat-tab>
                  <mat-tab *ngIf="access['black-list'].main_route_access.includes(user?.role)"  label="BLACKLIST"></mat-tab>
                  <mat-tab  *ngIf="access.configuration.main_route_access.includes(user?.role)" label="CONFIGURATION"></mat-tab>
                  <mat-tab  *ngIf="access.administration.main_route_access.includes(user?.role)" label="ADMINISTRATION"></mat-tab>
                  <mat-tab  *ngIf="access.auditability.main_route_access.includes(user?.role)" label="AUDITABILITY"></mat-tab>
        </mat-tab-group>
      </span>
      <span class="user-infos"></span>
      <span class="user-name">
        <div class="navbar">
          <div class="dropdown">
              <button class="dropbtn">
                <div class="userName">{{ user?.firstname }} {{ user?.lastname }}</div>
                <div class="icon-holder"><img src="../../../../assets/images/imence.png" alt="user-image"/></div>
              </button>
              <div class="dropdown-content">
                <a (click)="logout()"><i class="bi bi-box-arrow-left mr-1"></i>Log Out</a>
              </div>
            </div>
          </div>
      </span>
    </mat-toolbar>
  `
})
export class HeaderComponent implements OnInit {
  @Input() user: { firstname: string; lastname: string; role: string };
  @Output() goToLanding: EventEmitter<void> = new EventEmitter<void>();
  access = ACCESS;
  selectedIndex: number = 0;
  igg = '';

  constructor(private router: Router, private location: Location, private cognitoService: CognitoService, private configService: ConfigService) {
  }

  ngOnInit(): void {
    if (this.location.path() === '/kyt') {
      this.goToLanding.emit();
    }
    const storedIndex = sessionStorage.getItem('selectedTabIndex');
    if (storedIndex !== null) {
      this.selectedIndex = parseInt(storedIndex, 10);
    }
    this.getIgg();
  }

  tabClick(event: MatTabChangeEvent): void {
    const selectedIndex = event.index;
    sessionStorage.setItem('selectedTabIndex', selectedIndex.toString());

    switch (event.tab.textLabel) {
      case 'HOMEPAGE':
        const hfilteredAccess = this.access.home.main_route_access.includes(this.user.role);
        if (hfilteredAccess) {
          this.router.navigate(['/home']).then();
        }
        break;
      case 'ALERTES LISTES':
        const kfilteredAccess = this.access.kyt.internal_routes.filter((route) => route.route_access.includes(this.user.role));
        if (kfilteredAccess.length > 0) {
          this.router.navigate([kfilteredAccess[0].route]).then();
        }
        break;
      case 'BLACKLIST':
        const bfilteredAccess = this.access['black-list'].internal_routes.filter((route) => route.route_access.includes(this.user.role));
        if (bfilteredAccess.length > 0) {
          this.router.navigate([bfilteredAccess[0].route]).then();
        }
        break;
      case 'ADMINISTRATION':
        this.router.navigate(['/administration']).then();
        break;
      case 'AUDITABILITY':
        this.router.navigate(['/auditability']).then();
        break;
      case 'CONFIGURATION':
        const filteredAccess = this.access.configuration.internal_routes.filter((route) => route.route_access.includes(this.user.role));
        if (filteredAccess.length > 0) {
          this.router.navigate([filteredAccess[0].route]).then();
        }
        break;
      case 'ALERTS FOLLOW-UP':
        const afilteredAccess = this.access['alerts-follow-up'].internal_routes.filter((route) => route.route_access.includes(this.user.role));
        if (afilteredAccess.length > 0) {
          this.router.navigate([afilteredAccess[0].route]).then();
        }
        break;
    }
  }

  getIgg(): void {
    this.cognitoService.getUser().then((info: { username: string }) => {
      if (info?.username.indexOf('DigitalPass_') !== -1) {
        this.igg = info?.username.split('DigitalPass_')[1];
      } else {
        this.igg = info?.username.split(this.configService.getConfig()?.gigya + '_')[1];
      }
    }).then(() => {});
  }

  logout(): void { 
    const cognitiveLocalStorageStr = `CognitoIdentityServiceProvider.6oqgsn3f814s65qbk4d5c0i36t.DigitalPass_${this.igg}`;
    sessionStorage.clear();
    localStorage.removeItem('followUpFilters');
    localStorage.removeItem('pageIndex');
    localStorage.removeItem('email')
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
    localStorage.removeItem('country');
    localStorage.removeItem('type');
    localStorage.removeItem('productFamily');
    localStorage.removeItem('alertSource');
    localStorage.removeItem('status');
    localStorage.removeItem('statusAffiliate');
    localStorage.removeItem('searchKey');
    localStorage.removeItem('pcomNumber');
    localStorage.removeItem('searchSiteCode');
    localStorage.removeItem('minScore');
    localStorage.removeItem('maxScore');
    localStorage.removeItem('minMlScore');
    localStorage.removeItem('maxMlScore');
    localStorage.removeItem('minAmt');
    localStorage.removeItem('maxAmt');
    localStorage.removeItem('minTransactions');
    localStorage.removeItem('maxTransactions');
    localStorage.removeItem('minRules');
    localStorage.removeItem('maxRules');
    localStorage.removeItem('clientId');
    localStorage.removeItem(`${cognitiveLocalStorageStr}.accessToken`);
    localStorage.removeItem(`${cognitiveLocalStorageStr}.idToken`);
    localStorage.removeItem(`${cognitiveLocalStorageStr}.refreshToken`);
    localStorage.removeItem(`${cognitiveLocalStorageStr}.userData`);
    localStorage.removeItem(`${cognitiveLocalStorageStr}.clockDrift`);
    localStorage.removeItem(`${cognitiveLocalStorageStr}.LastAuthUser`);
    window.location.href = environment.config.baseURL;
  }
}
