import { Component, Input } from '@angular/core';
import { AppSandboxService } from '../../app-sandbox.service';

@Component({
  selector: 'kyt-progress-bar',
  template: `

<mat-accordion>
              <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{title}}
                <span *ngIf="date?.startDate && date?.endDate">Calculated from {{date?.startDate | date : 'dd MMM' }} to {{date.endDate | date : 'dd MMM' }} </span>
                <span *ngIf="!date?.endDate">Calculated on {{date?.startDate | date : 'dd MMM'}}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
            <!-- Analyst -->

              <div *ngIf="view?.admin.includes(user.role)">
                <label class="label" for="analyst">Analyst</label>
                <div class="progress-container">
                  <div [style.width]="getCompletionPercentage(status?.analystProfile)+'%'" class="completed">
                      <span class="completion-text">
                        {{completedPercentage}}%
                      </span>
                  </div>
                </div>
              <div class="progress-status-wrapper">
                <span>To do: {{status?.analystProfile.toDo}}</span>
                <span>Done: {{status?.analystProfile.done}}</span>
              </div>
            </div>
              <!-- Affiliate -->
            <div  *ngIf="view?.affiliate.includes(user.role)">
            <label class="label" for="analyst">Affiliate</label>
                <div class="progress-container">
                  <div [style.width]="getCompletionPercentage(status?.affiliateProfile)+'%'" class="completed">
                      <span class="completion-text">
                        {{completedPercentage}}%
                      </span>
                  </div>
                </div>
              <div class="progress-status-wrapper">
                <span>To do: {{status?.affiliateProfile.toDo}}</span>
                <span>Done: {{status?.affiliateProfile.done}}</span>
              </div>
            </div>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() status: { 'analystProfile': {toDo: number; done: number},
                    'affiliateProfile': {toDo: number; done: number}};
  @Input() title: string;
  @Input() date: {startDate: string | null, endDate: string | null};
  @Input() view: {admin: string[], affiliate: string[], role: string};
  panelOpenState = true;
  completedPercentage: number;
  user = this.sb?.currentUser?.getValue();

  constructor(private sb: AppSandboxService) { }
  getCompletionPercentage(status: {toDo: number; done: number}): number{
    if (status?.done !== 0 ) {
       this.completedPercentage = Number(((status?.done / (status?.toDo + status?.done)) * 100).toFixed(2));
    }else{
      this.completedPercentage = 0;
    }
    return this.completedPercentage;
  }
}
